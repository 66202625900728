<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-0">Syper</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title
            id="login-title"
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Bem vindo ao Syper! 👋
          </b-card-title>
          <b-card-text class="mb-2"> Faça login na sua conta </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    data-cy="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Esqueceu a senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      data-cy="password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Lembrar Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                data-cy="btn-entrar"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import mixinsGlobal from '@/mixins'

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility, mixinsGlobal],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      email
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  mounted() {
    if (!localStorage.getItem('pageReloaded')) {
      // Limpa os dados do localStorage e sessionStorage
      localStorage.clear()
      sessionStorage.clear()

      // Define uma flag para evitar recarregamentos infinitos
      localStorage.setItem('pageReloaded', 'true')

      // Recarrega a página ignorando o cache
      window.location.reload(true)
    } else {
      // Remove a flag após o primeiro reload para futuras execuções
      localStorage.removeItem('pageReloaded')
    }
  },

  methods: {
    getAbilityUser(scope) {
      const arrayAbility = scope
      let userAbility = []
      if (arrayAbility.length) {
        userAbility = arrayAbility.map((item) => ({
          action: 'ROLE_' + item,
          subject: 'ACL'
        }))
        return userAbility
      }
    },
    userObj(user, scope) {
      const newUser = {
        id: user.id,
        email: user.email,
        nome: user.firstName,
        username: user.firstName,
        fullName: `${user.firstName} ${user.lastName}`,
        role: user.perfil.nome,
        ability: this.getAbilityUser(scope),
        extras: {
          eCommerceCartItemsCount: 1
        }
      }
      return newUser
    },
    getUsersById(id, scope) {
      useJwt.getUsersById(id).then((response) => {
        const userData = this.userObj(response.data, scope)
        localStorage.setItem('userData', JSON.stringify(userData))
        this.$ability.update(userData.ability)
        this.$router
          .replace(getHomeRouteForLoggedInUser(userData.ability))
          .then(
            () => {},
            (err) => {
              console.error('err', err)
            }
          )
          .catch((error) => {
            this.$refs.loginForm.setErrors(error.response.error)
          })
      })
    },
    async login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              username: this.userEmail,
              password: this.password,
              grant_type: 'password'
            })
            .then((response) => {
              useJwt.setToken(response.data.access_token)
              useJwt.setRefreshToken(response.data.refresh_token)
              this.showMessageSuccess('Sucesso', 'Login Efetuado')
              this.$store
                .dispatch('usersSettingAccessModuleModule/loadPermission')
                .then(() => {
                  this.getUsersById(
                    response.data.user.id,
                    response.data.user.permissoes
                  )
                })
                .catch(() => {
                  this.MensagemError(
                    'Falha no carregamento',
                    'Falha ao carregar permissões do sistema'
                  )
                })
            })
            .catch((error) => {
              this.MensagemError(
                'Falha no Login',
                `Email ou Senha Incorretos - ${error.error_description}`
              )
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
